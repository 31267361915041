import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useState, useRef } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Header from './Header';
import { NavLink } from "react-router-dom/cjs/react-router-dom";

const Arcade = (props) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const swiperRef = useRef(null);

  const openLightbox = (index) => {
    setLightboxOpen(true);
    setLightboxIndex(index);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxIndex(0);
  };

  const handleMouseEnter = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.start();
    }
  };

  return (

    <>
   

    <section 
      id="testimonials" 
      className="testimonials" 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
    >
      {/* <div className="main-heading">
        <h2 className="heading">{props?.title || ''}</h2>
      </div> */}
  
      {/* Category Circle - Always Centered Over the Swiper */}
      <div className="category-circle">
        {props && props.title  ? props.title : ''}
        
      </div>
  
      <Swiper
        ref={swiperRef}
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={30}
        slidesPerView={2}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 10 },
          480: { slidesPerView: 1, spaceBetween: 20 },
          668: { slidesPerView: 1, spaceBetween: 30 },
          900: { slidesPerView: 2, spaceBetween: 30 },
        }}
      >
        <div className="clients-slider swiper four-col-category-list" data-aos="fade-up" data-aos-delay="100">
          <div className="swiper-wrapper four-col-category-list">
            {props?.data?.map((deep, d) => (
              <SwiperSlide key={d}>
                <div className="bus-feed-item-primary">
                  <div className="biz-feed-item-primary-element">
                    <img 
                      src={deep.image.replace('/thumbnails_watermark','/thumbnails')} 
                      title={deep.title} 
                      className="lazy-img-bg"
                    />
                    <div className="biz-feed-item-element-meta">
                      <div className="biz-primary-title">
                        <a href={deep.slug}>{deep.title}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </section>
    </>
  );
  
}

export default Arcade;
