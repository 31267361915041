const CategoryListData = [
    {
        
        name: 'ALL News',
        slug: 'all',
        hindi_name: 'सभी खबरें',
        show_on: 0
    },
    {
        name: 'National123',
        slug: 'national',
        hindi_name: 'राष्ट्रीय',
        show_on: 1
    },
    {
        name: 'International',
        slug: 'international',
        hindi_name: 'अंतरराष्ट्रीय',
        show_on: 1
    },
    {
        name: 'Business',
        slug: 'business',
        hindi_name: 'व्यापार',
        show_on: 1
    },
    {
        name: 'Sports',
        slug: 'sports',
        hindi_name: 'खेल',
        show_on: 1
    },
    {
        name: 'Science/Tech',
        slug: 'science-tech',
        hindi_name: 'विज्ञान/प्रौद्योगिकी',
        show_on: 1
    },
    {
        name: 'Entertainment',
        slug: 'entertainment',
        hindi_name: 'मनोरंजन',
        show_on: 1
    },
    {
        name: 'Health/Medicine',
        slug: 'health-medicine',
        hindi_name: 'स्वास्थ्य/चिकित्सा',
        show_on: 1
    },    
    {   
        name: 'IPL 2025',
        slug: 'ipl2025',
        hindi_name: 'आईपीएल 2025',
        show_on: 1
    },    
    {    id: 3,
        name: 'Fashion',
        slug: 'fashion',
        hindi_name: 'फैशन',
        show_on: 0
    },
    {
        name: 'Market',
        slug: 'market',
        hindi_name: 'बाजार',
        show_on: 0
    },
    {
        name: 'Opinion/Commentary',
        slug: 'commentary',
        hindi_name: 'विचार / टिप्पणी',
        show_on: 0
    },
    {
        name: 'Motorsports',
        slug: 'motorsports',
        hindi_name: 'मोटरस्पोर्ट्स',
        show_on: 0
    },
    {
        name: 'Opinion/Specials',
        slug: 'opinion-specials',
        hindi_name: 'विचार / विशेष',
        show_on: 0
    },
    {
        name: 'Hockey',
        slug: 'hockey',
        hindi_name: 'हॉकी',
        show_on: 0
    },
    {
        name: 'Athletics',
        slug: 'athletics',
        hindi_name: 'एथलेटिक्स',
        show_on: 0
    },
    {
        name: 'Badminton',
        slug: 'badminton',
        hindi_name: 'बैडमिंटन',
        show_on: 0
    },
    {
        name: 'Books',
        slug: 'books',
        hindi_name: 'किताबें',
        show_on: 0
    },
    {
        name: 'Culture',
        slug: 'culture',
        hindi_name: 'संस्कृति',
        show_on: 0
    },
    {
        name: 'Golf',
        slug: 'golf',
        hindi_name: 'गोल्फ़',
        show_on: 0
    },
    {
        name: 'WildLife',
        slug: 'wildlife',
        hindi_name: 'वन्य जीवन',
        show_on: 0
    },
    {
        name: 'Rights',
        slug: 'rights',
        hindi_name: 'अधिकार',
        show_on: 0
    },
    {
        name: 'Media',
        slug: 'media',
        hindi_name: 'मिडिया',
        show_on: 0
    },
    {
        name: 'Defence',
        slug: 'defence',
        hindi_name: 'रक्षा',
        show_on: 0
    },
    {
        name: 'Southern Cinema',
        slug: 'southern-cinema',
        hindi_name: 'साउथर्न  सिनेमा',
        show_on: 0
    },
    {
        name: 'Education',
        slug: 'education',
        hindi_name: 'शिक्षा',
        show_on: 0
    },
    {
        name: 'Other Sports',
        slug: 'other-sports',
        hindi_name: 'अन्य खेल',
        show_on: 0
    },
    {
        name: 'Bollywood',
        slug: 'bollywood',
        hindi_name: 'बॉलीवुड',
        show_on: 0
    },
    {
        name: 'OTT',
        slug: 'ott',
        hindi_name: 'ओटीटी',
        show_on: 0
    },
    {
        name: 'TV',
        slug: 'tv',
        hindi_name: 'टेलीविजन',
        show_on: 0
    },
    {
        name: 'Tennis',
        slug: 'tennis',
        hindi_name: 'टेनिस',
        show_on: 0
    },
    {
        name: 'Cinema',
        slug: 'cinema',
        hindi_name: 'सिनेमा',
        show_on: 0
    },
    {
        name: 'Showbiz',
        slug: 'showbiz',
        hindi_name: 'शोबिज़',
        show_on: 0
    },
    {
        name: 'Music',
        slug: 'music',
        hindi_name: 'संगीत',
        show_on: 0
    },
    {
        name: 'Hollywood',
        slug: 'hollywood',
        hindi_name: 'हॉलीवुड',
        show_on: 0
    },
    {
        name: 'South Asia',
        slug: 'south-asia',
        hindi_name: 'दक्षिण एशिया',
        show_on: 0
    },
    {
        name: 'Cricket',
        slug: 'cricket',
        hindi_name: 'क्रिकेट',
        show_on: 0
    },
    {
        name: 'Environment',
        slug: 'environment',
        hindi_name: 'पर्यावरण',
        show_on: 0
    },
    {
        name: 'Disaster',
        slug: 'disaster',
        hindi_name: 'आपदा',
        show_on: 0
    },
    {
        name: 'Football',
        slug: 'football',
        hindi_name: 'फ़ुटबॉल',
        show_on: 0
    },
    {
        name: 'Security',
        slug: 'security',
        hindi_name: 'सुरक्षा',
        show_on: 0
    },
    {
        name: 'Religion',
        slug: 'religion',
        hindi_name: 'धर्म',
        show_on: 0
    },
    {
        name: 'Crime',
        slug: 'crime',
        hindi_name: 'अपराध',
        show_on: 0
    },
    {
        name: 'Society',
        slug: 'society',
        hindi_name: 'समाज',
        show_on: 0
    },
    {
        name: 'Human Interest',
        slug: 'human-interest',
        hindi_name: 'मानवीय रुचि',
        show_on: 0
    },
    {
        name: 'Diaspora',
        slug: 'diaspora',
        hindi_name: 'प्रवासी',
        show_on: 0
    },
    {
        name: 'Diplomacy',
        slug: 'diplomacy',
        hindi_name: 'कूटनीति',
        show_on: 0
    },
    {
        name: 'Accident',
        slug: 'accident',
        hindi_name: 'दुर्घटना',
        show_on: 0
    },
    {
        name: 'Terrorism',
        slug: 'terrorism',
        hindi_name: 'आतंकवाद',
        show_on: 0
    },
    {
        name: 'Law',
        slug: 'law',
        hindi_name: 'कानून',
        show_on: 0
    },
    {
        name: 'Politics',
        slug: 'politics',
        hindi_name: 'राजनीति',
        show_on: 0
    },

    {
        name: 'Lifestyle',
        slug: 'lifestyle',
        hindi_name: 'जीवन शैली',
        show_on: 0
    }
];

export default CategoryListData