import React,{useState, useEffect,useRef, useCallback} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns';
import { useHistory } from "react-router-dom";
import HomeSlider from './HomeSlider';
import HeadingTab from './HeadingTab';
import TopPic from './TopPic';
import {useStickyBox} from "react-sticky-box";
import VideoCat from './videoCat'
import PortfolioPageSlider from './PortfolioPageSlider';
const PhotoHome = ()=>{

const stickyRef = useStickyBox({offsetTop: 200, offsetBottom: 1000})
const [topData, setTopData] = useState()
const [engData, setEngData] = useState()
const categoryList = [
    {
        name:'General',
        slug:'national/1'
    },
    {
        name:'International',
        slug:'international/1'
    },
    {
        name:'Politics',
        slug:'politics/1'
    },
    {
        name:'Entertainment',
        slug:'entertainment/1'
    },    
    {
        name:'Sports',
        slug:'sports/1'
    },    

    {
        name:'Business',
        slug:'business/1'
    },
    {
        name:'Fashion',
        slug:'fashion/1'
    },
    {
        name:'Cinema',
        slug:'cinema/1'
    },
    {
        name:'WildLife',
        slug:'wildLife/1'
    },
    {
        name:' IPL 2025',
        slug:'ipl2025/1'
    },
    // {
    //     name:'T20 World Cup 2024',
    //     slug:'t20worldcup2024'
    // },
    // {
    //     name:'Paris Olympics 2024',
    //     slug:'olympics2024/1'
    // }     
]

// const [backImageHome, setbackImageHome] = useState('')
const [cateText, setCategoryName] = useState('all')
const [cateName, setCateName] = useState('')
const [searchText, setSearchText] = useState('')
const [inputSearch, setSearch] = useState()
const inputRef = useRef(null)
const {categoryId, search, offset,} = useParams()
const [currentPage, setCurrentPage] = useState(1)
const [pageList, setPageList] = useState([1,2,3])
const [lastPage, setLastPage] = useState(1)
const [lt, setLt] = useState(5)
const history = useHistory()
const [requestFlag, setRequestFlag] = useState(true)
const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
const getCatgoryData=(obj)=>{
    if(obj != cateText){
        setCategoryName(obj)
        setCurrentPage(1)
        history.push('/english-wire/1');
    }
    //fetchData()
}
const getTopNewsData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/imagenews/list/?top_news=true`
    )
    .then((res) => {
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.map((item) => {
 
        return {
            id: item.id,
            image: item.image,
            title: item.title,
            slug:'/photo-event/'+item.slug,
            dateTime: item.created_at ? formatDate(item.created_at): ''
        }})
        setTopData(latestStories)
    })
 }
const searchData=(data)=>{
    setSearchText(data)
    history.push('/photo-wire/'+data+'/1');

}

const fetchData = async (page) => {
   // alert(categoryId)
   
    let getApii = `https://cms.iansnews.in/api/elastic/imagenews/list/?language=english`;
    if(categoryId && categoryId !='all'){
        //cateText = `&tags=${cateText}`;
       getApii += `&tags=${categoryId}`;
    }
    if(inputSearch){
       // getApii += `&query=${inputSearch}`;
    }
    
    getApii += `&page=${page}`;
    page = page ? page: 1;

    axios.get(getApii)
    .then(res => {
    // if(lastPage == 1){
         setLastPage(res.data.numpages);
    // }
    const getStories =
    res.data.results &&
    res.data.results.length > 0 &&
    res.data.results.map((item) => {
    // let tgSt= item.tags.filter(it => it.name === 'Business')
    return {
        content: item.caption,
        id: item.id,
        image: item.image ? item.image : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
        tags:item.tags,
        image_count: item.image_count,
        dateTime: item.created_at ? formatDate(item.created_at): '',
        title: item.title,
        slug:'/photo-event/'+item.slug
        // slugType: tgSt && tgSt.length > 0 ? true : false,
    }})
    // setbackImageHome(`background: url(${res.data.results[0].image}) no-repeat 50% 50%`)
    setEngData(getStories);
    setLt(res.data.numpages);
    setRequestFlag(false);
    })
    .catch(err => {
    // console.log('Google api calendar error', err)
    })
}

const handleNextPage = () => {
    if(lt > currentPage){
        setCurrentPage(parseInt(currentPage) + 1)
    }
}

const [isVisible, setIsVisible] = useState(false);
const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth"
})}
const handlePrevPage = () => {
    if(currentPage > 1){
        setCurrentPage(currentPage - 1);
    }
}
const inputSData=(event)=>{
    setSearch(event.target.value);
  }
  const onSubmit = (e) => {
    e.preventDefault()

    let path = '/all-photo-search/'+inputSearch;
        //SetSHead('template-search')
        //window.reload()
        window.location.pathname=path;
        history.push(path);
    // console.log(conFom)
}
const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
        setIsVisible(true)
    } else {
        setIsVisible(false)
    }
}
const [startPg, setStartPage] = useState(1)
const inputGSData=(event)=>{
    setStartPage(event.target.value)

  }
const GoToPage=(e)=>{
    e.preventDefault()
    setCurrentPage(startPg);
    let pathh = '/photo-wire/all/'+startPg
    

    history.push(pathh)
    
}
const [wcdData, setWcDdata] = useState('')
const [genData, setGenDdata] = useState('')
const [intdData, setIntDdata] = useState('')
const [entData, setEntDdata] = useState('')
const [sportsData, setSportsDdata] = useState('')
const [politicsData, setPoliticsDdata]=useState('')
const [IplData, setIplDdata]=useState('')
const cateData =(type)=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/imagenews/list/?tags=${type}`
    ).then((res) => {
    
        if(res.data.results &&
        res.data.results.length > 0){
            const wwwData =
            res.data.results &&
            res.data.results.length > 0 &&
            res.data.results.map((item) => {
            return {
                byline: item.byline,
                content: item.images && item.images.length > 0 && item.images[0].caption? item.images[0].caption : '',
                id: item.id,
                //image: item.image,
                image: item.images && item.images.length > 0 && item.images[0].image? item.images[0].image : '',
                title: item.title,
                image_count:item.image_count,
                images:item.images,
                tags:item.tags && item.tags.length > 0 ? item.tags:'',
                dateTime: item.created_at ? formatDate(item.created_at): '',
                slug:'/photo-event/'+item.slug,
                // slugType: tgSt && tgSt.length > 0 ? true : false,
                video_preview:item.video_url? item.video_url :'',
                image_caption: item.image_caption
            }})
            if(type=='ipl2025'){
                setIplDdata(wwwData)
            }else if(type=='national'){
                setGenDdata(wwwData)
            }else if(type=='international'){
                setIntDdata(wwwData)                
            }else if(type=='entertainment'){
                setEntDdata(wwwData)      
            }else if(type=='sports'){
                setSportsDdata(wwwData)     
            }else if(type=='politics'){
                setPoliticsDdata(wwwData)                                 
            }
        } 
    });
} 
useEffect(() => {
    if(categoryId){
        setCategoryName(categoryId)
    }
    setCurrentPage(offset)
    setStartPage(offset)
    if(currentPage == offset){
        fetchData(currentPage)
        setStartPage(currentPage)
    }
    getTopNewsData()
    cateData('national')
    cateData('international')
    cateData('entertainment')
    cateData('sports')
    cateData('politics')
    cateData('ipl2025')
    scrollToTop()
    if(!localStorage || !localStorage.accessToken || !localStorage.photoStatus){
        localStorage.setItem('loginUrl', '/photo-wire-home/');
        history.push('/login');
    }
    window.addEventListener("scroll", toggleVisibility)
    return () => window.removeEventListener("scroll", toggleVisibility)
//  .catch(err => { console.log('Google api calendar error', err) })
}, [currentPage, categoryId, search, offset])
return(
<>
<Helmet>
   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
   <title>Indo-Asian News Service-{cateName ? cateName.toUpperCase() : ''}</title>
   <meta data-meta-title="IANS IN" name="keywords" content={''} />
   <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
</Helmet>
<Header currU={'/photo-wire-home/'}/>
{/* <HomeSlider/> */}

<main id="main">
    <div className="container video-newsec photo-cont">
        <section id="about" className="about">
            <HeadingTab />
                <div className="main-heading">
                    <div className="video-tab-sec photo-tab-sec">
                        <div class="mob-sidebar">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <NavLink to={`/photo-wire-home/`} className='nav-link'>Home</NavLink>
                            </li>                                
                            <li className="nav-item">
                                <NavLink to={`/photo-wire/all/1`} className='nav-link '>ALL Events</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className='nav-link ' to={`/all-photo-wire/all/1`}>ALL Photos</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`/photo-wire/national/1`} className='nav-link'>General</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`/photo-wire/politics/1`} className='nav-link'>Politics</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`/photo-wire/sports/1`} className='nav-link'>Sports</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`/photo-wire/entertainment/1`} className='nav-link'>Entertainment</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`/photo-wire/international/1`} className='nav-link'>International</NavLink>
                            </li>                                    
                            </ul>
                        </div>
                       
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        More
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="/photo-wire/ipl2025/1">IPL&nbsp;2025</Dropdown.Item>
                                        <Dropdown.Item href="/photo-wire/business/1">Business</Dropdown.Item>
                                        <Dropdown.Item href="/photo-wire/cinema/1">Cinema</Dropdown.Item>
                                        <Dropdown.Item href="/photo-wire/fashion/1">Fashion</Dropdown.Item>
                                        <Dropdown.Item href="/photo-wire/features/1">Features</Dropdown.Item>
                                        <Dropdown.Item href="/photo-wire/wildLife/1">WildLife</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                        <div className="search-form-allphoto">
                            <form onSubmit={onSubmit}>            
                                <input type="search" ref={inputRef} placeholder="Search...." onChange={inputSData} name="search" value={inputSearch}/>
                                <span className="input-group-text border-0" onClick={onSubmit}><i className="bi bi-search" aria-hidden="true"></i></span>
                            </form>                      
                        </div>  
                    </div>
                </div>
            <TopPic  title="TOP PHOTOS" data={topData} pTag={true}/>
            <PortfolioPageSlider name={"IPL 2025"} data={IplData} link={'/photo-wire/ipl2025/1'}/>
            <PortfolioPageSlider name={"Politics"} data={politicsData} link={'/photo-wire/politics/1'}/>
            <PortfolioPageSlider name={"General"} data={genData} link={'/photo-wire/national/1'}/>
            <PortfolioPageSlider name={"Enterainment"} data={entData} link={'/photo-wire/enterainment/1'}/>
            <PortfolioPageSlider name={"Sports"} data={sportsData} link={'/photo-wire/sports/1'}/>
        </section>
    </div>
</main>               
<Footer />  
</>
)
}
export default PhotoHome