import React from 'react';
import Slider from "react-slick"
import axios from 'axios';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {NavLink} from 'react-router-dom';
export default function PortfolioPageSlider(props) {
 
  var settings = {
    autoplay: false,
    autoplaySpeed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    initialSlide: 0, // Always start from first slide
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false, // Disable infinite scroll
          initialSlide: 1, // Ensure first slide loads properly
          centerMode: false, // Disable center mode to prevent blank space
        },
      },
    ],
  }; 
      return (
        <>
            <section className="testimonials testimonials-photo" id="latest">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12 photo-home-cat">
                          <div className="category-circle">
                          <NavLink to={props && props.link ? props.link : ''} title= {props && props.name ? props.name : ''}>
                            {props && props.name ? props.name : ''}
                          </NavLink>
                          </div>
                      </div>
                     
                      <Slider {...settings} key={props.data.length} className="slick-slider2">
                        {props &&
                          props.data &&
                          props.data.length > 0 &&
                          props.data.map((item, i) => (
                            <div className="sec-left img-home-col4" key={i}>
                              <NavLink to={item.slug} title={item.title}>
                                <div className="thumb w-100 album-thumb">
                                  <img className="thumb-image w-100" src={item.image} alt={item.title} />
                                  <div className="photo-count-picH">
                                    <span> <i className="bi bi-camera"></i> </span> {item.image_count}
                                  </div>
                                  <div className="dec-img">
                                    <span className="des-pic-title">{item.title}</span>
                                    <span className="des-pic-label">{item.dateTime}</span>
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                          ))}
                      </Slider>
                    </div>
              </div>
            </section>  
        </>

      );
    }
